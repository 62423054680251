import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';
//routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import { AuthProtected, AccessRoute } from './AuthProtected';

const Index = () => {
   const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
   const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
   return (
      <React.Fragment>
         <Switch>
            <Route path={availablePublicRoutesPaths}>
               <NonAuthLayout>
                  <Switch>
                     {publicRoutes.map((route, idx) => (
                        <Route
                           path={route.path}
                           // component={route.component}
                           key={'publicRoutes' + idx}
                           exact={true}
                           render={(props) => {
                              return (
                                 <Suspense fallback={null}>
                                    <route.component {...props} />
                                 </Suspense>
                              );
                           }}
                        />
                     ))}
                  </Switch>
               </NonAuthLayout>
            </Route>

            <Route path={availableAuthRoutesPath}>
               <AuthProtected>
                  <VerticalLayout>
                     <Switch>
                        {authProtectedRoutes.map((route, idx) => (
                           <AccessRoute
                              path={route.path}
                              component={route.component}
                              key={'authProtectedRoutes' + idx}
                              exact={true}
                           />
                        ))}
                     </Switch>
                  </VerticalLayout>
               </AuthProtected>
            </Route>
         </Switch>
      </React.Fragment>
   );
};

export default Index;
