import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from '../../../assets/images/users/avatar-1.jpg';

const ProfileDropdown = () => {
   const history = useHistory();

   const userRoleTitle = 'super Admin';

   const [userName, setUserName] = useState('Admin');

   //Dropdown Toggle
   const [isProfileDropdown, setIsProfileDropdown] = useState(false);
   const toggleProfileDropdown = () => {
      setIsProfileDropdown(!isProfileDropdown);
   };

   const handleLogout = (e) => {
      e.stopPropagation();
      e.preventDefault();

      localStorage.clear();
      history.push('/login');
   };

   return (
      <React.Fragment>
         <Dropdown
            isOpen={isProfileDropdown}
            toggle={toggleProfileDropdown}
            className="ms-sm-3 header-item topbar-user"
         >
            <DropdownToggle tag="button" type="button" className="btn">
               <span className="d-flex align-items-center">
                  <img
                     className="rounded-circle header-profile-user"
                     src={avatar1}
                     alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                     <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                        {userName}
                     </span>
                     <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                        {userRoleTitle}
                     </span>
                  </span>
               </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
               <h6 className="dropdown-header">Welcome {userName}!</h6>
               <DropdownItem href="/profile">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                  <span className="align-middle">Profile</span>
               </DropdownItem>

               <DropdownItem onClick={handleLogout}>
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
                  <span className="align-middle csm-btn-reset" data-key="t-logout">
                     Logout
                  </span>
               </DropdownItem>
            </DropdownMenu>
         </Dropdown>
      </React.Fragment>
   );
};

export default ProfileDropdown;
