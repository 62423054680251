import React from 'react';
import { Redirect } from 'react-router-dom';

//login
const Login = React.lazy(() => import('../pages/Authentication/Login'));

// User Profile
const UserProfile = React.lazy(() => import('../pages/Authentication/user-profile'));

// New AuthProtectedRoutes Imports
const Users = React.lazy(() => import('../pages/Users'));
const Employees = React.lazy(() => import('../pages/Employees'));
const Distributors = React.lazy(() => import('../pages/Distributors'));
const DistributorsSubUsers = React.lazy(() => import('../pages/DistributorsSubUsers'));
const DistributorsStocks = React.lazy(() => import('../pages/DistributorsStocks'));
const ProductStockRequests = React.lazy(() => import('../pages/ProductStockRequests'));
const Products = React.lazy(() => import('../pages/Products'));
const ProductVarients = React.lazy(() => import('../pages/ProductVarients'));
const ProductOffers = React.lazy(() => import('../pages/ProductOffers'));
//const DashBoard = React.lazy(() => import('../pages/Dashboard'));
const ProductCategories = React.lazy(() => import('../pages/ProductCategories'));
const ProductApplications = React.lazy(() => import('../pages/ProductApplications'));
const Orders = React.lazy(() => import('../pages/Orders'));
const Logistics = React.lazy(() => import('../pages/Logistics'));
const SupportTickets = React.lazy(() => import('../pages/SupportTickets'));
const GeneralUtils = React.lazy(() => import('../pages/GeneralUtils'));
const CustomizedRecipes = React.lazy(() => import('../pages/CustomizedRecipe'));
const BulkOrders = React.lazy(() => import('../pages/BulkOrders'));

const authProtectedRoutes = [
   //  { path: '/dashboard', component: DashBoard },
   { path: '/profile', component: UserProfile },
   // New AuthProtected Routes
   { path: '/users', component: Users },
   { path: '/employees', component: Employees },
   { path: '/distributors', component: Distributors },
   { path: '/distributors/subusers/:id', component: DistributorsSubUsers },
   { path: '/distributors/product-quantity/:id', component: DistributorsStocks },
   { path: '/product-quantity/requests', component: ProductStockRequests },
   { path: '/products', component: Products },
   { path: '/product-variant', component: ProductVarients },
   { path: '/customized-recipes', component: CustomizedRecipes },
   { path: '/bulk-order', component: BulkOrders },
   { path: '/product-offers', component: ProductOffers },
   { path: '/product-categories', component: ProductCategories },
   { path: '/product-applications', component: ProductApplications },
   { path: '/orders', component: Orders },
   { path: '/logistics', component: Logistics },
   { path: '/support-tickets', component: SupportTickets },
   { path: '/general-utils', component: GeneralUtils },

   // this route should be at the end of all other routes
   // eslint-disable-next-line react/display-name
   {
      path: '/',
      exact: true,
      component: () => <Redirect to="/products" />,
   },
];

const publicRoutes = [
   // Authentication Page
   { path: '/login', component: Login },

   //AuthenticationInner pages
   // { path: '/auth-signin-basic', component: BasicSignIn },
];

export { authProtectedRoutes, publicRoutes };
