import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'reactstrap';
// import { Engagespot } from "@engagespot/react-component";

// Import Components
import ProfileDropdown from '../components/common/Layout/ProfileDropdown';
import LightDark from '../components/common/Layout/LightDark';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
   const [search, setSearch] = useState(false);

   const toogleSearch = () => {
      setSearch(!search);
   };

   const toogleMenuBtn = () => {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open');

      //For collapse horizontal menu
      if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
         document.body.classList.contains('menu')
            ? document.body.classList.remove('menu')
            : document.body.classList.add('menu');
      }

      //For collapse vertical menu
      if (document.documentElement.getAttribute('data-layout') === 'vertical') {
         if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove('vertical-sidebar-enable');
            document.documentElement.getAttribute('data-sidebar-size') === 'sm'
               ? document.documentElement.setAttribute('data-sidebar-size', '')
               : document.documentElement.setAttribute('data-sidebar-size', 'sm');
         } else if (windowSize > 1025) {
            document.body.classList.remove('vertical-sidebar-enable');
            document.documentElement.getAttribute('data-sidebar-size') === 'lg'
               ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
               : document.documentElement.setAttribute('data-sidebar-size', 'lg');
         } else if (windowSize <= 767) {
            document.body.classList.add('vertical-sidebar-enable');
            document.documentElement.setAttribute('data-sidebar-size', 'lg');
         }
      }

      //Two column menu
      if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
         document.body.classList.contains('twocolumn-panel')
            ? document.body.classList.remove('twocolumn-panel')
            : document.body.classList.add('twocolumn-panel');
      }
   };

   return (
      <React.Fragment>
         <header id="page-topbar" className={headerClass}>
            <div className="layout-width">
               <div className="navbar-header">
                  <div className="d-flex">
                     <div className="navbar-brand-box horizontal-logo">{/* Logo Links */}</div>
                     <button
                        onClick={toogleMenuBtn}
                        type="button"
                        className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                        id="topnav-hamburger-icon"
                     >
                        <span className="hamburger-icon">
                           <span></span>
                           <span></span>
                           <span></span>
                        </span>
                     </button>
                  </div>

                  <div className="d-flex align-items-center">
                     {/* Search Dropdown */}
                     <Dropdown
                        isOpen={search}
                        toggle={toogleSearch}
                        className="d-md-none topbar-head-dropdown header-item"
                     >
                        {/* Dropdown content */}
                     </Dropdown>
                     {/* Dark/Light Mode set */}
                     <LightDark
                        layoutMode={layoutModeType}
                        onChangeLayoutMode={onChangeLayoutMode}
                     />
                     {/* Engagespot */}
                     {/* {userUniqueId && (
                <Engagespot
                  apiKey={`${process.env.REACT_APP_ENGAGESPOT_API_KEY}`}
                  userId={userUniqueId}
                  theme={{
                    notificationButton: {
                      iconFill: "#003632",
                    },
                  }}
                ></Engagespot>
              )} */}

                     {/* ProfileDropdown */}
                     <ProfileDropdown />
                  </div>
               </div>
            </div>
         </header>
      </React.Fragment>
   );
};

export default Header;
