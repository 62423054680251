import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const Navdata = () => {
   const history = useHistory();
   // State data
   const [isDashboard, setIsDashboard] = useState(false);
   const [isProductSettings, setIsProductSettings] = useState(false);
   const [isUserRequest, setIsUserRequest] = useState(false);
   const [isGeneralSettings, setIsGeneralSettings] = useState(false);
   const [iscurrentState, setIscurrentState] = useState('Dashboard');

   function updateIconSidebar(e) {
      const target = e.target; // Use type assertion here

      if (target.getAttribute && target.getAttribute('subitems')) {
         const ul = document.getElementById('two-column-menu');
         const iconItems = ul?.querySelectorAll('.nav-icon.active');
         const activeIconItems = iconItems ? Array.from(iconItems) : [];
         activeIconItems.forEach((item) => {
            item.classList.remove('active');
            const id = item.getAttribute('subitems');
            if (document.getElementById(id)) {
               document.getElementById(id)?.classList.remove('show');
            }
         });
      }
   }

   useEffect(() => {
      document.body.classList.remove('twocolumn-panel');
      if (iscurrentState !== 'Product Settings') {
         setIsProductSettings(false);
      }
   }, [history, iscurrentState]);

   const menuItems = [
      {
         label: 'Pages',
         isHeader: true,
      },
      // {
      //    id: 'dashboard',
      //    label: 'Dashboards',
      //    icon: 'ri-home-6-line',
      //    link: '/dashboard',
      //    stateVariables: isDashboard,
      //    click: function (e) {
      //       e.preventDefault();
      //       setIsDashboard(!isDashboard);
      //       updateIconSidebar(e);
      //    },
      // },
      {
         id: 'products',
         label: 'Products',
         icon: 'ri-shopping-bag-line',
         link: '/products',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'orders',
         label: 'Orders',
         icon: ' ri-shopping-cart-2-line',
         link: '/orders',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'users',
         label: 'Users',
         icon: 'ri-user-follow-line',
         link: '/users',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'employees',
         label: 'Employees',
         icon: 'ri-team-line',
         link: '/employees',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'distributors',
         label: 'Distributors',
         icon: 'ri-truck-line',
         link: '/distributors',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'offers',
         label: 'Offers',
         icon: 'ri-percent-fill',
         link: '/product-offers',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'logistics',
         label: 'Logistics',
         icon: 'ri-earth-line',
         link: '/logistics',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'support-tickets',
         label: 'Support Tickets',
         icon: 'ri-discuss-line',
         link: '/support-tickets',
         click: function (e) {
            e.preventDefault();
         },
      },
      {
         id: 'request',
         label: 'User Request',
         icon: 'ri-inbox-archive-line',
         link: '/#',
         stateVariables: isUserRequest,
         click: function (e) {
            e.preventDefault();
            setIsUserRequest(!isUserRequest);
            setIscurrentState('User Request');
            updateIconSidebar(e);
         },
         subItems: [
            {
               id: 'customized-recipes',
               label: 'Customized Recipe',
               link: '/customized-recipes',
               parentId: 'request',
            },
            {
               id: 'bulk-order',
               label: 'Bulk Orders',
               link: '/bulk-order',
               parentId: 'request',
            },
            {
               id: 'product-quantity-requests',
               label: 'Stock requests',
               link: '/product-quantity/requests',
               parentId: 'request',
            },
         ],
      },
      {
         id: 'product-settings',
         label: 'Product Settings',
         icon: 'ri-settings-6-line',
         link: '/#',
         stateVariables: isProductSettings,
         click: function (e) {
            e.preventDefault();
            setIsProductSettings(!isProductSettings);
            setIscurrentState('Product Settings');
            updateIconSidebar(e);
         },
         subItems: [
            {
               id: 'productCategories',
               label: 'Categories',
               link: '/product-categories',
               parentId: 'product-settings',
            },
            {
               id: 'productApplications',
               label: 'Applications',
               link: '/product-applications',
               parentId: 'product-settings',
            },
         ],
      },
      {
         id: 'general-settings',
         label: 'General Settings',
         icon: ' ri-settings-2-line',
         link: '/#',
         stateVariables: isGeneralSettings,
         click: function (e) {
            e.preventDefault();
            setIsGeneralSettings(!isGeneralSettings);
            setIscurrentState('General Settings');
            updateIconSidebar(e);
         },
         subItems: [
            {
               id: 'generalUtils',
               label: 'Utils',
               link: '/general-utils',
               parentId: 'general-settings',
            },
         ],
      },
   ];

   return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
