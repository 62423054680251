import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { configureStore } from './store/themeHandle';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         retry: process.env.NODE_ENV === 'production',
         refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      },
   },
});

root.render(
   <>
      <BrowserRouter>
         <QueryClientProvider client={queryClient}>
            <Provider store={configureStore({})}>
               <App />
               <ToastContainer hideProgressBar={true} />
            </Provider>
         </QueryClientProvider>
      </BrowserRouter>
   </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
