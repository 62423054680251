import React from "react";
import { Redirect, Route } from "react-router-dom";
import RolesAuth from "./RolesAuth";

const AuthProtected = ({ location, children }) => {
  const token = localStorage.getItem("token");
  /*
   redirect un-auth access protected routes via URL
   */
  if (!token) {
    return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
  }

  return <>{children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <React.Suspense fallback={null}>
          <RolesAuth>
            <Component {...props} />
          </RolesAuth>
        </React.Suspense>
      )}
    />
  );
};

export { AuthProtected, AccessRoute };
