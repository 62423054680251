import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

// Import images
import logoSm from '../assets/images/logo-white.png';
import logoDark from '../assets/images/logo-cheflin-green.png';
import logoLight from '../assets/images/logo-cheflin-white.png';

// Import Components
import VerticalLayout from './VerticalLayouts';
import { Container } from 'reactstrap';

const Sidebar = ({ layoutType }) => {
   useEffect(() => {
      var verticalOverlay = document.getElementsByClassName('vertical-overlay');
      if (verticalOverlay) {
         verticalOverlay[0].addEventListener('click', function () {
            document.body.classList.remove('vertical-sidebar-enable');
         });
      }
   });

   const addEventListenerOnSmHoverMenu = () => {
      // add listener Sidebar Hover icon on change layout from setting
      if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
         document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
      } else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
         document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
      } else {
         document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
      }
   };

   return (
      <React.Fragment>
         <div className="app-menu navbar-menu">
            <div className="navbar-brand-box">
               <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                     <img src={logoSm} alt="" height="32" />
                  </span>
                  <span className="logo-lg">
                     <img src={logoDark} alt="" height="50" />
                  </span>
               </Link>

               <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                     <img src={logoSm} alt="" height="32" />
                  </span>
                  <span className="logo-lg">
                     <img src={logoLight} alt="" height="52" />
                  </span>
               </Link>
               <button
                  onClick={addEventListenerOnSmHoverMenu}
                  type="button"
                  className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                  id="vertical-hover"
               >
                  <i className="ri-record-circle-line"></i>
               </button>
            </div>

            <React.Fragment>
               <SimpleBar id="scrollbar" className="h-100">
                  <Container fluid>
                     <div id="two-column-menu"></div>
                     <ul className="navbar-nav" id="navbar-nav">
                        <VerticalLayout layoutType={layoutType} />
                     </ul>
                  </Container>
               </SimpleBar>
               <div className="sidebar-background"></div>
            </React.Fragment>
         </div>
         <div className="vertical-overlay"></div>
      </React.Fragment>
   );
};

export default Sidebar;
